import * as React from 'react';
import { Link } from 'gatsby';
import { css, Themed, Flex } from 'theme-ui';
import Bio from 'gatsby-theme-blog/src/components/bio';
import tw from 'twin.macro';

const PostChangeLink = props => <Themed.a {...props} css={tw`text-text`} />;

const PostFooter = ({ previous, next }) => (
    <footer css={tw`py-12 text-text`}>
        {(previous || next) && (
            <Flex
                as="ul"
                css={css({
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0
                })}
            >
                <li>
                    {previous && (
                        <PostChangeLink as={Link} to={previous.slug} rel="prev">
                            ← {previous.title}
                        </PostChangeLink>
                    )}
                </li>
                <li>
                    {next && (
                        <PostChangeLink as={Link} to={next.slug} rel="next">
                            {next.title} →
                        </PostChangeLink>
                    )}
                </li>
            </Flex>
        )}
    </footer>
);

export default PostFooter;
