import * as React from 'react';
import { getSrc } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from './layout';
import SEO from 'gatsby-theme-blog/src/components/seo';
import PostTitle from 'gatsby-theme-blog/src/components/post-title';
import PostFooter from 'gatsby-theme-blog/src/components/post-footer';
import PostHero from 'gatsby-theme-blog/src/components/post-hero';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import { Themed, jsx } from 'theme-ui';

const Post = ({
    data: {
        post,
        site: {
            siteMetadata: { title }
        }
    },
    location,
    previous,
    next
}) => {
    return (
        <Layout location={location} title={title}>
            <SEO
                title={post.title}
                description={post.excerpt}
                imageSource={
                    post.socialImage
                        ? getSrc(post.socialImage)
                        : getSrc(post.image)
                }
                imageAlt={post.imageAlt}
            />
            <main>
                <article>
                    <Link
                        css={tw`text-white hover:text-brandOrange text-sm pb-2 inline-block`}
                        to={'/blog'}
                    >
                        Back to all posts
                    </Link>
                    <header css={tw`mb-6`}>
                        <PostHero post={post} />
                        <PostTitle css={tw`text-text text-3xl pt-4`}>
                            {post.title}
                        </PostTitle>
                        <p css={tw`text-text text-xl`}>{post.date}</p>
                    </header>
                    <section>
                        <MDXRenderer>{post.body}</MDXRenderer>
                    </section>
                </article>
            </main>
            <PostFooter {...{ previous, next }} />
        </Layout>
    );
};

export default Post;
